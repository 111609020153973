import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet'
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Post, postService } from '../Services/post-service';
import { useParams } from 'react-router-dom';
import { Loype, loypeService } from '../Services/loype-service';
import L from 'leaflet';

export type PostEtterSjekk = {
  post_id: number,
  post_navn: string,
  loype_id: number,
  longitude: number,
  latitude: number,
  fullført: boolean
}

function Map(loype_id: any) {

  const url_id = useParams<{ id: string }>();
  loype_id = parseInt(url_id.id)
  const [posts, setPosts] = useState<Post[]>([])
  const [loype, setLoype] = useState<Loype>()

  //henter poster
  useEffect(() => {
    postService.getLoypePoster(loype_id).then(function (response) {
      return setPosts(response)
    })
  }, [])

  //henter løype
  useEffect(() => {
    loypeService.get(loype_id).then(function (response) {
      return setLoype(response)
    })
  }, [])

  // Hent fullførte poster fra localStorage hvis det finnes
  const lagredePoster = localStorage.getItem('fullførtePoster');
  let fullførtePoster: number[] = lagredePoster ? JSON.parse(lagredePoster) : [];


  //Setter ny konstant med poster som inneholder om de er fullførte eller ikke,
  //denne brukes til å sjekke hvilken markør postene skal ha
  const posterEtterSjekk: PostEtterSjekk[] = posts.map(post => ({
    ...post,
    fullført: fullførtePoster.includes(post.post_id)
  }));



  //Returnerer kartkomponent som viser kart med postene til valgt løype
  return (
    <div>{
      loype && (<MapContainer key={loype.loype_id} center={[(loype?.center_lat), (loype?.center_long)]} zoom={14} scrollWheelZoom={true} style={{ height: '93vh' }} className="container-fluid">
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {posterEtterSjekk.map(post => {
          /* Link som genereres for å gi veibeskrivelse ved hjelp av Google Maps */
          let googleLink = `https://www.google.com/maps/search/?api=1&query=${JSON.stringify(post.latitude)}%2C${JSON.stringify(post.longitude)}`;
          return (
            <React.Fragment key={post.post_id}>
              {post.fullført ?
                <Marker key={post.post_id} position={[post.latitude, post.longitude]} icon={greenIcon}>
                  <Popup key={post.post_id}>
                    {post.post_navn}
                    <br />
                    <a href={googleLink}>Få veibeskrivelse</a>
                  </Popup>
                </Marker> :
                <Marker key={post.post_id} position={[post.latitude, post.longitude]}>
                  <Popup key={post.post_id}>
                    {post.post_navn}
                    <br />
                    <a href={googleLink}>Få veibeskrivelse</a>
                  </Popup>
                </Marker>
              }
            </React.Fragment>
          )
        })}
      </MapContainer>)
    }</div>
  );
}

//Green marker from https://github.com/pointhi/leaflet-color-markers
let greenIcon = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

export default Map;