import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Card, Nav } from 'react-bootstrap';
import { Link} from 'react-router-dom';
import { Loype, loypeService } from '../Services/loype-service';
// import { AuthContext } from '../context/AuthContext';

function Loyper() {
  const [loyper, setLoyper] = useState<Loype[]>([])
  // const user = React.useContext(AuthContext)
  const user = false




  //henter alle løypene
  useEffect(() => {
    loypeService.getAll().then(function (response) {
      return setLoyper(response)
    })
  }, [])


//card som  viser frem løypene som en knapp som leder til løypens poster mm.
  return (
    <Card style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', border: 'none' }}>
      {loyper.map((loype, index) => {
        return (
          <Card.Body style={{ display: "flex", flexDirection: "row" }} key={index}>
            <Button style={{ minWidth: "30vw", height: "40px", margin: '2vh' }} key={loype.loype_id}>
              <Nav.Link key={loype.loype_id} as={Link} to={'/kart/' + loype.loype_id}>
                {loype.loype_navn}
              </Nav.Link>
            </Button>
            {user && < Button style={{ margin: '2vh', height: "40px", }}>
              <Nav.Link as={Link} to={'/endreloype/' + loype.loype_id}>
                Endre
              </Nav.Link>
            </Button>}
          </Card.Body>
        )
      })}

{/* Knapp for å opprette ny løype */}
      {user && <Button  >
        <Nav.Link as={Link} to={'/nyloype'}>
          Opprett ny løype
        </Nav.Link>
      </Button>}
    </Card>
  )
}

export default Loyper;