import React, { useContext } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
// import { AuthContext } from '../context/AuthContext';
// import { signOut } from './login';
import { Button } from 'react-bootstrap';
import logo from "../Bilder/sort_logo_uskrift.png";

function NavigationBar() {

  // const user = useContext(AuthContext)

  //naviasjonsbar for å navigere til hjem, faq og løyper
  //viser også hvilken epost som er innlogget
  return (

    <Navbar collapseOnSelect expand="lg" className='container-fluid ' style={{ fontSize: "22px", border: "none" }}>
      <Container fluid>
        <Navbar.Brand href="/">
          <img
            src={logo}
            width="30"
            height="30"
            className="justify-content-md-center"
            alt="Gjærevollsenteret logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll" style={{ zIndex: "100" }}>
          <Nav className="me-auto my-10 my-lg-0" navbarScroll>
            <Nav.Link as={Link} to="/" href="/">
              Hjem
            </Nav.Link>
            <Nav.Link as={Link} to="/FAQ" href="/FAQ" >
              FAQ
            </Nav.Link>
            <Nav.Link as={Link} to="/loyper" href="/loyper">
              Løyper
            </Nav.Link>

          </Nav>
        </Navbar.Collapse>

      </Container>
      {/* <div >
        {user && <div style={{ fontSize: "15px" }}>{user.email} er logget inn</div>}

        {user && <Button type="button" variant="secondary" onClick={signOut}>Logg ut </Button>}
      </div> */}
    </Navbar>
  );
}

export default NavigationBar;